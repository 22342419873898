import { useEffect, useState } from 'react';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import moment from 'moment';

// material
import {
    Modal,
    TextField,
    Box,
    Typography,
} from '@mui/material';
import Services from '../../../service/Services';
import { localdata } from '../../../service/localdata';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    // boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

const EventModel = ({ open, onClose, data }) => {
    const [editedData, setEditedData] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [fileData, setFile] = useState();

    useEffect(() => {
        setEditedData({ ...data });
    }, [data])

    const submitData = () => {
        const userData = localdata.GET_DATA();
        setLoading(true);
        if (editedData.id) {
            axios.put(Services.EVENTS(editedData.id), editedData, {
                headers: {
                    Authorization: `Bearer ${userData.token}`,
                },
            }).then(() => {
                if (fileData) {
                    uploadFile(editedData.id);
                } else {
                    onClose();
                }
            }).finally(() => {
                setLoading(false);
            });
            return;
        }
        axios.post(Services.EVENTS(), editedData, {
            headers: {
                Authorization: `Bearer ${userData.token}`,
            },
        }).then((e) => {
            if (fileData) {
                uploadFile(e.data.result.id);
            } else {
                onClose();
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    const uploadFile = async (id) => {
        setLoading(true);
        const userData = localdata.GET_DATA();
        axios.post(`${Services.EVENTS()}/image/${id}`, {
            base64Image: await toBase64(fileData)
        }, {
            headers: {
                Authorization: `Bearer ${userData.token}`,
            },
        }).then(e => {
            onClose();
        }).finally(() => {
            setLoading(false);
        })
    }

    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" style={{ marginBottom: 10 }}>
                    Add/Update Event
                </Typography>

                <Grid container spacing={2} className='mt-2'>
                    <Grid item xs={12}>
                        <TextField
                            label="Event Name"
                            className='w-100'
                            value={editedData.title}
                            onChange={(e) => setEditedData({ ...editedData, title: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            className='w-100'
                            label="Description"
                            multiline
                            rows={2}
                            value={editedData.description}
                            onChange={(e) => setEditedData({ ...editedData, description: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            className='w-100'
                            label="Start Date"
                            type='datetime-local'
                            value={moment(
                                editedData.startDate ? 
                                    editedData.startDate : 
                                    moment(editedData.startDate).startOf('days')).format('YYYY-MM-DDTHH:mm')
                            }
                            onChange={(e) => setEditedData({ ...editedData, startDate: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            className='w-100'
                            label="End Date"
                            type='datetime-local'
                            value={moment(
                                editedData.endDate ? 
                                editedData.endDate : 
                                moment(editedData.endDate).startOf('days')).format('YYYY-MM-DDTHH:mm')
                            }
                            onChange={(e) => {
                                console.log(e.target.value)
                                setEditedData({ ...editedData, endDate: e.target.value })
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <p className='m-0 p-0'>Event Image</p>
                        <input className='form-control w-100' type='file' accept='image/*' onChange={(e) => {
                                setFile(e.target.files[0])
                            }} />
                    </Grid>
                </Grid>
                <Grid container className='mt-4' spacing={2}>
                    <Grid item xs={6} />
                    <Grid item xs={6} className="text-end">
                        <LoadingButton
                            onClick={onClose}
                            size="large"
                            type="submit"
                            className='me-3'
                            variant="outlined"
                            disabled={isLoading}
                            sx={{ my: 1 }}
                        >
                            Cancel
                        </LoadingButton>
                        <LoadingButton
                            onClick={submitData}
                            loading={isLoading}
                            size="large"
                            type="submit"
                            variant="contained"
                            sx={{ my: 1 }}
                        >
                            Update
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}

export default EventModel;
