import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Modal,
  TextField,
  Box,
  Typography,
  Stack,
  FormControl,
  InputLabel,
} from '@mui/material';
// component
import Iconify from '../../../components/Iconify';
import Services from '../../../service/Services';
import { localdata } from '../../../service/localdata';

// ----------------------------------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  // boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

export default function UserMoreMenu({
  id,
  selectedUserData,
  UserDelete,
  userUpdateStatus,
  setError,
  blocked,
  setSuccess2,
  setUpdateLoading,
  RefreshData,
}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [groupList, setGroupList] = useState([]);

  const [editedData, setEditedData] = useState(selectedUserData);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const UpdateUser = () => {
    setOpen2(false);
    setUpdateLoading(true);
    const userData = localdata.GET_DATA();
    console.log(editedData);
    axios
      .put(Services.UPDATE_USER(id), editedData, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          setSuccess2(true);
          setTimeout(() => {
            setSuccess2(false);
          }, 3000);
          RefreshData();
          setUpdateLoading(false);
        } else {
          setError(true);
          setTimeout(() => {
            setError(false);
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  };

  const getGroups = () => {
    const userData = localdata.GET_DATA();
    axios
      .get(Services.GROUP(0, 0, 1000), {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        setGroupList([...res.data.groups])
      }).catch(() => {
        setGroupList([]);
      })
  }

  const getCountryList = () => {
    axios.get(Services.COUNTRY()).then((e) => {
      setCountryList(e.data.results.countries);
    });
  }

  const getStateList = (name) => {
    axios.get(Services.STATE(name)).then((e) => {
      setStateList(e.data.results.states);
    });
  }

  const getCityList = (name) => {
    axios.get(Services.CITY(name)).then((e) => {
      setCityList(e.data.results.cities);
    });
  }

  useEffect(() => {
    getGroups();
    getCountryList();
  }, [open2])

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }} onClick={() => setOpen2(true)}>
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        {
          !blocked && <MenuItem sx={{ color: 'text.secondary' }} onClick={() => setOpen3(true)}>
            <ListItemIcon>
              <Iconify icon="basil:user-block-solid" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Block" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        }
        {
          !!blocked && <MenuItem sx={{ color: 'text.secondary' }} onClick={() => setOpen3(true)}>
            <ListItemIcon>
              <Iconify icon="gg:unblock" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Unblock" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        }
        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => setOpen(true)}>
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>

      <Modal
        open={open2}
        onClose={() => setOpen2(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{ marginBottom: 10 }}>
            Update User
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className='d-flex'>
                {editedData.picture && <div className='me-4'>
                  <img className='mb-4 border rounded' src={editedData.picture} alt={editedData.name} height="150" />
                </div>
                }
                <div className='me-2'>
                  <h4>{editedData.name}</h4>
                  <p className='text-secondary'>{editedData.email}</p>
                  <p>Warrior - ({editedData.isWarrior ? 'Yes' : 'No'})</p>
                  {/* <a href='#'>Remove Image</a> */}
                </div>
              </div>
              <div className='col-md-12 mt-0 p-0 mb-2'>
                <hr />
              </div>

            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                size="small"
                label="First Name"
                value={editedData.firstName || editedData.name}
                onChange={(e) => setEditedData({ ...editedData, firstName: e.target.value })}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                label="Last Name"
                value={editedData.lastName}
                onChange={(e) => setEditedData({ ...editedData, lastName: e.target.value })}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                label="Email"
                value={editedData.email}
                onChange={(e) => setEditedData({ ...editedData, email: e.target.value })}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                label="Mobile Number"
                value={editedData.mobile}
                onChange={(e) => setEditedData({ ...editedData, mobile: e.target.value })}
              />
            </Grid>
            <Grid item xs={4} style={{ display: 'flex' }}>
              <span style={{ alignSelf: 'center' }}>D.O.B</span>
              <TextField
                size="small"
                id="dob"
                type={'date'}
                value={editedData.dateOfBirth}
                onChange={(e) => setEditedData({ ...editedData, dateOfBirth: e.target.value })}
                style={{ marginLeft: 10 }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '95%' }}>
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={editedData.gender}
                  label="Gender"
                  size="small"
                  onChange={(e) => setEditedData({ ...editedData, gender: e.target.value })}
                >
                  <MenuItem value={'male'}>Male</MenuItem>
                  <MenuItem value={'female'}>Female</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '95%' }}>
                <InputLabel id="demo-simple-select-label">isWarrior</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={editedData.isWarrior ? 1 : 0}
                  label="isWarrior"
                  size="small"
                  onChange={(e) => {
                    if (e.target.value === 1) {
                      setEditedData({ ...editedData, isWarrior: true });
                    } else {
                      setEditedData({ ...editedData, isWarrior: false });
                    }
                  }}
                >
                  <MenuItem value={0}>No</MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <TextField
                size="small"
                label="Address"
                value={editedData.address}
                onChange={(e) => setEditedData({ ...editedData, address: e.target.value })}
                style={{ width: '98%' }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '95%' }}>
                <InputLabel id="demo-simple-select-label">Country</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={editedData.country}
                  label="Country"
                  size="small"
                  onChange={(e) => {
                    getStateList(e.target.value);
                    setEditedData({ ...editedData, country: e.target.value })
                  }}
                >
                  {
                    countryList.map((e) => (
                      <MenuItem value={e.name}>{e.name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '95%' }}>
                <InputLabel id="demo-simple-select-label">State</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={editedData.state}
                  label="State"
                  size="small"
                  onChange={(e) => {
                    getCityList(e.target.value);
                    setEditedData({ ...editedData, state: e.target.value })
                  }}
                >
                  {
                    stateList.map((e) => (
                      <MenuItem value={e.name}>{e.name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '95%' }}>
                <InputLabel id="demo-simple-select-label">City</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={editedData.city}
                  label="City"
                  size="small"
                  onChange={(e) => {
                    setEditedData({ ...editedData, city: e.target.value })
                  }}
                >
                  {
                    cityList.map((e) => (
                      <MenuItem value={e.name}>{e.name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                label="PinCode"
                value={editedData.pinCode}
                onChange={(e) => setEditedData({ ...editedData, pinCode: e.target.value })}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl style={{ width: '95%' }}>
                <InputLabel id="demo-simple-select-label">User Group</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={editedData.userGroup}
                  label="User Group"
                  onChange={(e) => setEditedData({ ...editedData, userGroup: e.target.value })}
                >
                  {
                    groupList.map((e, i) => {
                      return (
                        <MenuItem value={e.name} key={i}>{e.name}</MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <LoadingButton
                onClick={() => setOpen2(false)}
                fullWidth
                size="large"
                type="submit"
                variant="outlined"
                sx={{ my: 2 }}
              >
                Cancel
              </LoadingButton>
            </Grid>
            <Grid item xs={6}>
              <LoadingButton
                onClick={UpdateUser}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                sx={{ my: 2 }}
              >
                Update
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Dialog
        open={open3}
        onClose={() => setOpen3(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Account {!blocked ? 'Block' : 'Unblock'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to {!blocked ? 'Block' : 'Unblock'} this Account?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ margin: 10 }}>
          <Button onClick={() => setOpen3(false)} variant="outlined" style={{ marginRight: 10 }}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              userUpdateStatus(id, !blocked);
              setOpen3(false);
            }}
            autoFocus
            variant="contained"
          >
            {!blocked ? 'Block' : 'Unblock'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Account Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this Account?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ margin: 10 }}>
          <Button onClick={() => setOpen(false)} variant="outlined" style={{ marginRight: 10 }}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              UserDelete(id);
              setOpen(false);
            }}
            autoFocus
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
