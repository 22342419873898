/* eslint-disable */
import { transform } from 'lodash';
import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// material
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  ListItemIcon,
  ListItemText,
  Typography,
  TableContainer,
  CircularProgress,
} from '@mui/material';
// component
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import Services from '../../../service/Services';

// ----------------------------------------------------------------------

export default function WarrierApprove({
  ApproveRequest,
  RejectRequest,
  userid,
  GetDiff,
  item,
  setIsOpen,
  isOpen,
  mainOpen,
  setMainOpen,
}) {
  const ref = useRef(null);

  const { updateRequest, user } = item;

  const titleCase = (text) => {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  return (
    <>
      <IconButton
        ref={ref}
        onClick={() => {
          setMainOpen(true);
          GetDiff(userid);
        }}
      >
        <Iconify icon="eva:checkmark-circle-2-fill" width={25} height={25} />
      </IconButton>

      <Dialog
        open={mainOpen}
        onClose={() => {
          setMainOpen(false);
          setIsOpen(false);
        }}
        fullWidth
        maxWidth={'100%'}
      >
        {!isOpen ? (
          <DialogContent style={{ textAlign: 'center' }}>
            <CircularProgress

            />
          </DialogContent>
        ) : (
          <>
            <DialogTitle id="alert-dialog-title">{'Approve Update'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" style={{ display: 'flex' }}>
                <TableContainer>
                  <Table style={{ minWidth: 600 }}>
                    <TableBody>
                      {Object.entries(updateRequest).map(([key, val]) => {
                        return (
                          key !== 'password' &&
                          key !== 'updatedAt' &&
                          key !== 'id' &&
                          key !== 'userId' && (
                            <TableRow key={key} style={{ margin: 0 }}>
                              <TableCell style={{ margin: 0 }}>
                                <Typography variant="subtitle2">{titleCase(key)}</Typography>
                              </TableCell>
                              <TableCell style={{ margin: 0 }}>
                                {
                                  key === 'picture' && <img src={user[key]} alt="user-profile" height={200} />
                                }
                                {
                                  key !== 'picture' && <Typography variant="subtitle2">{typeof user[key] === "boolean" ? (user[key] ? 'Yes' : 'No') : user[key]}</Typography>
                                }
                              </TableCell>
                              <TableCell style={{ margin: 0, backgroundColor: val !== user[key] ? '#baff9c' : '' }}>
                                {
                                  key === 'picture' && <img src={val} alt="user-profile" height={200} />
                                }
                                {
                                  key !== 'picture' && <Typography variant="subtitle2">{typeof val === "boolean" ? (val ? 'Yes' : 'No') : val}</Typography>
                                }
                              </TableCell>
                            </TableRow>
                          )
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ margin: 10 }}>
              <Button onClick={() => setMainOpen(false)} variant="outlined" style={{ marginRight: 10 }}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  RejectRequest(user.id);
                  setIsOpen(false);
                }}
                autoFocus
                variant="outlined"
              >
                Reject Request
              </Button>
              <Button
                onClick={() => {
                  ApproveRequest(user.id);
                  setIsOpen(false);
                }}
                autoFocus
                variant="contained"
              >
                Approve Request
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}
