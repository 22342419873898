import * as Yup from 'yup';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import Services from '../../../service/Services';
import { AuthContext } from '../../../AuthProvider';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const { setUserData } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [useremail, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [userpassword, setPassword] = useState('');
  const [error, setError] = useState(false);

  const onSubmit = async () => {
    setOpen(true);
    await axios
      .post(Services.LOGIN(), {
        email: useremail,
        password: userpassword,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.result.role === 'admin') {
          setUserData(res.data.result);
          const json = JSON.stringify(res.data.result);
          localStorage.setItem('account', json);
          navigate('/dashboard/app', { replace: true });
        } else {
          setError(true);
          setTimeout(() => {
            setError(false);
          }, 3000);
        }
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.message);
        setOpen(false);
        setError(true);
        if (err.response.data.errorMessage) {
          setErrorMessage(err.response.data.errorMessage)
        } else {
          setErrorMessage('Something went wrong please try again')
        }
        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  };

  useEffect(() => {
    const json = localStorage.getItem('account');
    const savedUser = JSON.parse(json);
    if (savedUser) {
      navigate('/dashboard/app', { replace: true });
    }
  }, []);

  return (
    <>
      {error && (
        <Alert variant="filled" severity="error" style={{ position: 'absolute', top: 10, right: 10 }}>
          <AlertTitle>Error</AlertTitle>
          {errorMessage} <strong>check it out!</strong>
        </Alert>
      )}

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Stack spacing={3}>
        <TextField
          fullWidth
          value={useremail}
          name="email"
          label="Email Address"
          onChange={(e) => setEmail(e.target.value)}
        />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        style={{ marginTop: 20 }}
        disabled={useremail === '' || userpassword === ''}
        onClick={onSubmit}
      >
        Login
      </LoadingButton>
    </>
  );
}
