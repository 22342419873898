import axios from "axios";
import { useEffect, useState } from "react";
import moment from "moment";
import { Box, CircularProgress } from "@mui/material";
import { transform } from "lodash";
import { Link as RouterLink } from 'react-router-dom';


import Services from "../../service/Services";
import { localdata } from "../../service/localdata";
import EventModel from "./model/EventModal";
import Iconify from "../../components/Iconify";


const Events = () => {

    const [eventList, setEvents] = useState([]);
    const [addPopup, setAddPopup] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [selectedData, setSelectedData] = useState(false);

    useEffect(() => {
        RefreshData();
    }, []);

    const RefreshData = () => {
        setLoading(true);
        const userData = localdata.GET_DATA();
        axios.get(Services.EVENTS(), {
            headers: {
                Authorization: `Bearer ${userData.token}`,
            },
        }).then((e) => {
            console.log(e.data)
            setEvents(e.data.data);
        }).finally(() => {
            setLoading(false);
        })
    }

    const deleteEvent = (id) => {
        const userData = localdata.GET_DATA();
        axios.delete(Services.EVENTS(id), {
            headers: {
                Authorization: `Bearer ${userData.token}`,
            },
        }).then((e) => {
            console.log(e.data)
            RefreshData();
        })
    }

    return (
        <div className="container mt-4">
            {isLoading && (
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '55%',
                            translate: transform('-50%', '-50%'),
                            zIndex: 9,
                        }}
                    />
                </Box>
            )}
            <EventModel open={addPopup} onClose={() => {
                setAddPopup(false)
                setSelectedData(false)
                RefreshData();
            }} data={selectedData} />
            <div className="d-flex justify-content-between">
                <h3>Events</h3>
                <button className="btn btn-primary" onClick={() => {
                    setAddPopup(true);
                    setSelectedData({});
                }}>Add Event</button>
            </div>
            <div className="row">
                {
                    !isLoading && eventList.map((e) => {
                        return (
                            <div className="col-md-4">
                                <a href={`/dashboard/event/${e.id}`} className="text-decoration-none">

                                    <img className="w-100 mt-2 object-fit-cover" height={200} alt="event" src={e.image || '/static/event.png'} />
                                </a>

                                <div className="bg-white p-4">
                                    <a href={`/dashboard/event/${e.id}`} className="text-decoration-none">

                                        <h4>{e.title}</h4>
                                        <p className="text-sm m-0 text-secondary" style={{ fontSize: 12 }}> ({moment(e.startDate).format('DD MMM, YYYY')} - {moment(e.endDate).format('DD MMM, YYYY')})</p>
                                        <p className="text-secondary mt-2">{e.description}</p>
                                    </a>
                                    <button className="btn btn-secondary" onClick={() => {
                                        setAddPopup(true);
                                        setSelectedData(e);
                                    }}>Edit</button>
                                    <button className="ms-3 btn btn-danger" onClick={() => {
                                        deleteEvent(e.id);
                                    }}>Delete</button>
                                    <button className="ms-3 float-end btn btn-primary" onClick={() => {
                                        navigator.clipboard.writeText(`https://salvationlamb.com/event/SL-${e.id}`)
                                    }}>
                                        Copy <Iconify icon="eva:copy-outline" width={20} height={20} />
                                    </button>
                                </div>
                            </div>
                        )
                    })
                }
                {
                    !isLoading && !eventList.length && <div className="col-12 mt-5 w-100">
                        <div className="text-left bg-white p-3">
                            <img alt="norecord" width={300} src="/static/norecord.jpg" />
                            <h4 className="mx-5">No records Found</h4>
                        </div>
                    </div>
                }
            </div>
        </div>

    )
}

export default Events;
