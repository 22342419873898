import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
// components
import Page from '../components/Page';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Logout() {
  useEffect(() => {
    localStorage.clear();
  }, []);
  return (
    <Page title="404 Page Not Found">
      <Container>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Logged out successfully
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            You have been logged out. If you would like to log back in, please click the login button.
          </Typography>
          <Button to="/" size="large" variant="contained" sx={{ mx: 'auto', my: { xs: 2, sm: 2 } }} component={RouterLink}>
            Go To Login
          </Button>
          <Box
            component="img"
            src="/static/illustrations/logout.svg"
            sx={{ height: 250, mx: 'auto', my: { xs: 0, sm: 0 } }}
          />


        </ContentStyle>
      </Container>
    </Page>
  );
}
