import axios from 'axios';
import { Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import Page from '../components/Page';
import Services from '../service/Services';
import { localdata } from '../service/localdata';
import Iconify from '../components/Iconify';

const { MaterialReactTable } = require("material-react-table");
const { useEffect, useMemo, useState } = require("react");


// ----------------------------------------------------------------------
const UserList = () => {

  const giftsList = [
    "No Gifts",
    "The Gift of Vision.",
    "The Discerning of Spirits.",
    "Different Kinds of Tongues.",
    "The Interpretation of Tongues"
  ]

  const religionList = [
    "SARC",
    "Adventist",
    "Anglican / Episcopal",
    "Apostolic",
    "Assembly of God (A.G)",
    "Assyrian",
    "Baptist",
    "Born Again",
    "Brethren",
    "Calvinist",
    "Christian",
    "Church of God",
    "Church of South India (C.S.I)",
    "Congregational",
    "Evangelical",
    "Jacobite",
    "Jehovah`s Witnesses",
    "Jewish",
    "Latin Catholic",
    "Latter day saints",
    "Lutheran",
    "Malankara",
    "Marthoma",
    "Melkite",
    "Mennonite",
    "Methodist",
    "Moravian",
    "Orthodox",
    "Others",
    "Pentecostal",
    "Presbyterian",
    "Protestant",
    "Roman Catholic",
    "Seventh-day Adventist",
    "Syrian Catholic",
    "Syro Malabar"
  ]

  const columns = useMemo(() => [
    { accessorKey: 'name', header: 'Name' },
    { accessorKey: 'email', header: 'Email' },
    { accessorKey: 'mobile', header: 'Mobile' },
    {
      accessorKey: 'isWarrior', header: 'Warrior', Cell: ({ cell, row }) => {
        return <span>{cell.getValue() ? 'Yes' : 'No'}</span>
      },
      filterVariant: 'select',
      filterSelectOptions: [
        {
          value: '1',
          text: 'Yes'
        },
        {
          value: '0',
          text: 'No'
        }
      ]
    },
    {
      accessorKey: 'blocked', header: 'Blocked', Cell: ({ cell, row }) => {
        return <span>{cell.getValue() ? 'Yes' : 'No'}</span>
      },
      filterVariant: 'select',
      filterSelectOptions: [
        {
          value: '1',
          text: 'Yes'
        },
        {
          value: '0',
          text: 'No'
        }
      ]
    },
    {
      accessorKey: 'gender', header: 'Gender',
      filterVariant: 'multi-select',
      filterSelectOptions: [
        {
          value: 'male',
          text: 'Male'
        },
        {
          value: 'female',
          text: 'Female'
        }
      ]
    },
    {
      accessorKey: 'religion', header: 'Religion',
      filterVariant: 'multi-select',
      filterSelectOptions: [
        ...religionList.map((data) => {
          return {
            value: data,
            text: data
          }
        })
      ]
    },
    {
      accessorKey: 'gift', header: 'Gift',
      filterVariant: 'multi-select',
      filterSelectOptions: [
        ...giftsList.map((data) => {
          return {
            value: data,
            text: data
          }
        })
      ]
    }
  ], []);

  const [data, setDate] = useState([]);
  const [isLoading, setLoading] = useState();

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    const userData = localdata.GET_DATA();
    setLoading(true);
    axios
      .get(Services.GET_ALL_USERS(0, 1000), {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.results.length > 0) {
          setDate(res.data.results);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div className="w-100 p-4">
      <Page title="User">
        <div className='d-flex justify-content-between pb-4'>
          <h4> Advance Filters</h4>

          <Button variant="contained" component={RouterLink} to="/dashboard/user" startIcon={<Iconify icon="eva:close" />}>
            Back
          </Button>
        </div>
        <MaterialReactTable
          columns={columns}
          isLoading={isLoading}
          data={data}
          enableRowSelection
          enableColumnOrdering
        />
      </Page>

    </div>
  )
}

export default UserList;