import { filter, transform } from 'lodash';
import { sentenceCase } from 'change-case';
import { useContext, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  TextField,
  Box,
  Backdrop,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import Services from '../service/Services';
import { AuthContext } from '../AuthProvider';
import { localdata } from '../service/localdata';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'mobile', label: 'Mobile', alignRight: false },
  { id: 'isWarrior', label: 'Warrior', alignRight: false },
  { id: 'blocked', label: 'Blocked', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  // boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

export default function User() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function applySortFilter(array, comparator, query, adFilter) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return paginate(rowsPerPage, page + 1, filter(
        array,
        (_user) =>
          (_user.firstName.toLowerCase().includes(query.toLowerCase()) ||
            _user.email.toLowerCase().includes(query.toLowerCase()) ||
            _user.mobile.toLowerCase().includes(query.toLowerCase()))
      ));
    }

    if (Object.keys(adFilter).length) {
      return paginate(rowsPerPage, page + 1, filter(
        array,
        (_user) => {
          return !!_user.isWarrior === adFilter.isWarrior && !!_user.blocked === adFilter.isBlocked
        }

      ));
    }
    return paginate(rowsPerPage, page + 1, stabilizedThis.map((el) => el[0]));
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = usersFullData.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setFilteredUsers(paginate(rowsPerPage, newPage + 1));
  };

  const paginate = (pageSize, pageNumber, array) => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    const res = [...[...(array || usersFullData)].slice((pageNumber - 1) * pageSize, pageNumber * pageSize)];

    return res;
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilteredUsers(paginate(parseInt(event.target.value, 10)), 1);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };


  const [filteredUsers, setFilteredUsers] = useState([]);
  const [usersFullData, setUsersFullData] = useState([]);
  // const isUserNotFound = filteredUsers.length === 0;

  const [updateLoading, setUpdateLoading] = useState(false);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [success2, setSuccess2] = useState(false);
  const [adFilter, setAdFilter] = useState({});

  const UserDelete = (deleteId) => {
    const userData = localdata.GET_DATA();
    axios
      .delete(Services.DELETE_USER(deleteId), {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        console.log(res);
        RefreshData();

        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  };

  const userUpdateStatus = (id, data) => {
    const userData = localdata.GET_DATA();
    axios
      .put(Services.BLOCK_UNBLOCK_USER(id), { status: data }, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        console.log(res);
        RefreshData();

        setSuccess2(true);
        setTimeout(() => {
          setSuccess2(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  };

  // applySortFilter(USERLIST, getComparator(order, orderBy), filterName);
  const [isLoading, setLoading] = useState(true);
  const RefreshData = () => {
    const userData = localdata.GET_DATA();
    setLoading(true);
    axios
      .get(Services.GET_ALL_USERS(0, 1000), {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.results.length > 0) {
          setFilteredUsers(paginate(rowsPerPage, page + 1, res.data.results));
          setUsersFullData(res.data.results);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const bulkUpdate = (status) => {
    const userData = localdata.GET_DATA();
    axios
      .post(Services.BULK_UPDATE(), {
        status,
        userIds: selected,
        action: 'block'
      }, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        RefreshData();

        setSuccess2(true);
        setTimeout(() => {
          setSuccess2(false);
        }, 3000);
        setLoading(false);
        setSelected([]);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
        setLoading(false);
      });
  }

  useEffect(() => {
    RefreshData();
  }, []);

  const NewfilteredUsers = applySortFilter(usersFullData, getComparator(order, orderBy), filterName, adFilter);

  return (
    <Page title="User">
      {success && (
        <Alert variant="filled" severity="success" style={{ position: 'absolute', top: 100, right: 10, zIndex: 999 }}>
          <AlertTitle>Success</AlertTitle>
          Account deleted successfully
        </Alert>
      )}

      {error && (
        <Alert variant="filled" severity="error" style={{ position: 'absolute', top: 100, right: 10, zIndex: 999 }}>
          <AlertTitle>Error</AlertTitle>
          Something Wrong <strong>check it out!</strong>
        </Alert>
      )}

      {success2 && (
        <Alert variant="filled" severity="success" style={{ position: 'absolute', top: 100, right: 10, zIndex: 999 }}>
          <AlertTitle>Success</AlertTitle>
          User details updated successfully
        </Alert>
      )}

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User
          </Typography>
          <Button variant="contained" component={RouterLink} to="/dashboard/userList" startIcon={<Iconify icon="eva:plus-fill" />}>
            Advance Filter
          </Button>
        </Stack>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999999 }}
          open={updateLoading}
          onClick={() => setUpdateLoading(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {isLoading ? (
          <Box sx={{ display: 'flex' }}>
            <CircularProgress
              style={{
                position: 'absolute',
                top: '50%',
                left: '55%',
                translate: transform('-50%', '-50%'),
                zIndex: 9,
              }}
            />
          </Box>
        ) : (
          <Card>
            <UserListToolbar
              updateFilter={(data) => {
                setAdFilter(data);
              }}
              numSelected={selected.length}
              bulkUpdate={bulkUpdate}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    isMultiSelect
                    headLabel={TABLE_HEAD}
                    rowCount={usersFullData.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {NewfilteredUsers.length > 0
                      ? NewfilteredUsers.map((row) => {
                        const { id, name, role, email, mobile, isWarrior, picture, blocked } = row;
                        const isItemSelected = selected.indexOf(id) !== -1;

                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, id)}
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  'aria-labelledby': id,
                                }}
                              />
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={10} style={{ marginLeft: 20 }}>
                                <Avatar alt={name} src={picture} />
                                <Typography variant="subtitle2" noWrap style={{ marginLeft: 10 }}>
                                  {name}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">{email}</TableCell>
                            <TableCell align="left">{mobile}</TableCell>
                            <TableCell align="left">{isWarrior ? 'Yes' : 'No'}</TableCell>
                            <TableCell align="left">{blocked ? 'Yes' : 'No'}</TableCell>

                            <TableCell align="right">
                              <UserMoreMenu
                                id={id}
                                blocked={blocked}
                                UserDelete={UserDelete}
                                userUpdateStatus={userUpdateStatus}
                                selectedUserData={row}
                                setSuccess2={setSuccess2}
                                setUpdateLoading={setUpdateLoading}
                                RefreshData={RefreshData}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })
                      : null}
                  </TableBody>

                  {NewfilteredUsers.length === 0 && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={usersFullData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )}
      </Container>
    </Page>
  );
}
