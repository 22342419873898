import * as React from "react";

export class PrintQR extends React.PureComponent {
    constructor(props) {
        super(props);
        if (this.props) {
            console.log('-')
        }
    }

    render() {
        const { event } = this.props;

        return (
            <div className="container text-center mt-5 p-4">

                <h2>{event.title}</h2>
                <p className="mt-2 px-3">{event.description}</p>
                <div className="d-flex justify-content-center">
                    <img className="px-5 mt-5 text-center" width={350} alt="event-qr" src={`https://api.qrserver.com/v1/create-qr-code/?size=500x500&data=https://salvationlamb.com/event/SL-${event.id}`} />
                </div>
                <p className="mt-3">For registration scan this QR</p>
            </div>
        );
    }
}
