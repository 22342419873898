import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useContext, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
// material
import {
  Card,
  Stack,
  Avatar,
  Button,
  Checkbox,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Alert,
  AlertTitle,
} from '@mui/material';
// components
import Label from '../components/Label';

import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, WarrierApprove } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import Services from '../service/Services';
import { localdata } from '../service/localdata';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'mobile', label: 'Mobile', alignRight: false },
  { id: 'isWarrior', label: 'Warrior', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.mobile.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Update() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setFilteredUsers(paginate(rowsPerPage, newPage + 1));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilteredUsers(paginate(parseInt(event.target.value, 10)), 1);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const paginate = (pageSize, pageNumber, array) => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    const res = [...[...(array || usersFullData)].slice((pageNumber - 1) * pageSize, pageNumber * pageSize)];
    console.log(res);
    return res;
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  // const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const [filteredUsers, setFilteredUsers] = useState([]);
  const [usersFullData, setUsersFullData] = useState([]);
  const isUserNotFound = filteredUsers.length === 0;
  const [isLoading, setLoading] = useState(true);
  const RefreshData = () => {
    const userData = localdata.GET_DATA();
    setLoading(true);
    axios
      .get(Services.GET_ALL_UPDATE_REQUESTS(), {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          setFilteredUsers(paginate(rowsPerPage, page + 1, res.data.users));
          setUsersFullData(res.data.users);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    RefreshData();
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const [error, setError] = useState(false);
  const [success1, setSuccess1] = useState(false);
  const [success2, setSuccess2] = useState(false);
  const [mainOpen, setMainOpen] = useState(false);

  const [requestResult, setRequestResult] = useState({});
  const GetDiff = (userid) => {
    const userData = localdata.GET_DATA();
    axios
      .get(Services.GET_DIFF_UPDATE(userid), {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        setIsOpen(true);
        setRequestResult(res.data.results);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const ApproveRequest = (userid) => {
    setIsOpen(true);
    const userData = localdata.GET_DATA();
    axios
      .post(
        Services.APPROVE_REQUEST(userid),
        {},
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setMainOpen(false);
        setSuccess1(true);
        setTimeout(() => {
          setSuccess1(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        setMainOpen(false);
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
      })
      .finally(() => {
        RefreshData();
      });
  };

  const RejectRequest = (userid) => {
    setIsOpen(true);
    const userData = localdata.GET_DATA();
    console.log(userData.token);
    axios
      .post(
        Services.REJECT_REQUEST(userid),
        {},
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setMainOpen(false);
        setSuccess2(true);
        setTimeout(() => {
          setSuccess2(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        setMainOpen(false);
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
      })
      .finally(() => {
        RefreshData();
      });
  };

  const NewfilteredUsers = applySortFilter(filteredUsers, getComparator(order, orderBy), filterName);

  return (
    <Page title="Update Request">
      {error && (
        <Alert variant="filled" severity="error" style={{ position: 'absolute', top: 100, right: 10, zIndex: 999 }}>
          <AlertTitle>Error</AlertTitle>
          Something Wrong <strong>check it out!</strong>
        </Alert>
      )}

      {success1 && (
        <Alert variant="filled" severity="success" style={{ position: 'absolute', top: 100, right: 10, zIndex: 999 }}>
          <AlertTitle>Success</AlertTitle>
          Update request approved successfully
        </Alert>
      )}

      {success2 && (
        <Alert variant="filled" severity="info" style={{ position: 'absolute', top: 100, right: 10, zIndex: 999 }}>
          <AlertTitle>Success</AlertTitle>
          Update request rejected
        </Alert>
      )}

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users Update Request
          </Typography>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={usersFullData.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {NewfilteredUsers.map((row) => {
                    const { id, userId, name, role, email, mobile, isWarrior, picture, blocked } = row;
                    const isItemSelected = selected.indexOf(name) !== -1;

                    return role !== 'admin' ? (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2} style={{ marginLeft: 15 }}>
                            <Avatar alt={name} src={picture} />
                            <Typography variant="subtitle2" noWrap style={{ marginLeft: 10 }}>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">{mobile}</TableCell>
                        <TableCell align="left">{isWarrior ? 'Yes' : 'No'}</TableCell>
                        {/* <TableCell align="left">
                          <Label variant="ghost" color={blocked ? 'error' : 'success'}>
                            dfgdfgdf
                          </Label>
                        </TableCell> */}
                        <TableCell align="right">
                          <WarrierApprove
                            ApproveRequest={ApproveRequest}
                            RejectRequest={RejectRequest}
                            userid={userId}
                            GetDiff={GetDiff}
                            item={requestResult}
                            setIsOpen={setIsOpen}
                            isOpen={isOpen}
                            setMainOpen={setMainOpen}
                            mainOpen={mainOpen}
                          />
                        </TableCell>
                      </TableRow>
                    ) : null;
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={usersFullData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
