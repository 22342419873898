import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { faker } from '@faker-js/faker';
import moment from 'moment/moment';
import { toInteger, transform } from 'lodash';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, CircularProgress, Box } from '@mui/material';
import Services from '../service/Services';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { AuthContext } from '../AuthProvider';
import { localdata } from '../service/localdata';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const theme = useTheme();

  const [allCounts, setAllCounts] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [statisticsDate, setStatisticsDate] = useState([]);
  const [statisticsValue, setStatisticsValue] = useState([]);
  // users count
  const RefreshData = () => {
    const userData = localdata.GET_DATA();
    setLoading(true);
    let flag1 = 0;
    let flag2 = 0;
    axios
      .get(Services.GET_ALL_COUNTS(), {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        console.log(res.data.results);
        setAllCounts(res.data.results);
        flag1 = 1;
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(Services.GET_STATISTICS(), {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.results.length > 0) {
          const sts = res.data.results;
          const arr1 = [];
          const arr2 = [];
          for (let i = 0; i < sts.length; i += 1) {
            arr1.push(moment(sts[i].date).format('MM/DD/YYYY'));
            arr2.push(toInteger(sts[i].count));
          }
          setStatisticsDate(arr1);
          setStatisticsValue(arr2);
          console.log(arr1);
          console.log(arr2);
        }
        flag2 = 1;
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(false);
  };

  useEffect(() => {
    RefreshData();
  }, []);
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          SalvationLamb Admin Tool
        </Typography>

        {isLoading ? (
          <Box sx={{ display: 'flex' }}>
            <CircularProgress
              style={{
                position: 'absolute',
                top: '50%',
                left: '55%',
                translate: transform('-50%', '-50%'),
                zIndex: 9,
              }}
            />
          </Box>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary title="Total users" link={'/dashboard/user'} total={allCounts.userCount} icon={'mdi:users-group-outline'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary title="Total Posts" link={'/dashboard/posts'} total={allCounts.postCount} color="info" icon={'bx:news'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Profiles Modified"
                link={'/dashboard/update'}
                total={allCounts.updateRequestCount}
                color="warning"
                icon={'material-symbols:change-circle'}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Today's Posts"
                link={'/dashboard/posts'}
                total={allCounts.todayPostCount}
                color="error"
                icon={'ic:outline-post-add'}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <AppWebsiteVisits
                title="Posts (Last 7 days)"
                chartLabels={statisticsDate}
                chartData={[
                  {
                    name: 'Post',
                    type: 'area',
                    fill: 'gradient',
                    data: statisticsValue,
                  }
                ]}
              />
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  );
}
