import PropTypes from 'prop-types';
// material
import { Grid, Button } from '@mui/material';
import PostCard from './PostCard';

// ----------------------------------------------------------------------

PostList.propTypes = {
  products: PropTypes.array.isRequired,
};

export default function PostList({ products, RefreshPostData, isOther, ...other }) {
  return (
    <>
      <Grid container spacing={3} {...other}>
        {products.map((product) => (
          <Grid key={product.id} item xs={12} sm={12} md={12}>
            <PostCard product={{
              ...product,
            }} RefreshPostData={RefreshPostData} isOther={isOther} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
