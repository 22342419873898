/* eslint-disable */

import { transform } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
// material
import ReactPaginate from 'react-paginate';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { Container, Stack, Typography, Box, Button, Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import FileUpload from 'react-material-file-upload';
import { RemoveScrollBar } from 'react-remove-scroll-bar';

// components
import Page from '../components/Page';
import { PostList } from '../sections/@dashboard/products';
// mock
import PRODUCTS from '../_mock/products';
import Services from '../service/Services';
import { AuthContext } from '../AuthProvider';
import { localdata } from '../service/localdata';
// ----------------------------------------------------------------------

const MyPaginate = styled(ReactPaginate).attrs({
  // You can redefine classes here, if you want.
  activeClassName: 'active', // default to "selected"
})`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  padding: 0 5rem;
  li a {
    border-radius: 7px;
    padding: 0.1rem 1rem;
    border: gray 1px solid;
    cursor: pointer;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.active a {
    background-color: #0366d6;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
function Items({ currentItems }) {
  return (
    <>
      {currentItems &&
        currentItems.map((item) => (
          <div>
            <h3>Item #{item}</h3>
          </div>
        ))}
    </>
  );
}

export default function Announcements({ itemsPerPage = 10 }) {
  const [itemOffset, setItemOffset] = useState(0);
  const [tags, setTag] = useState('');
  const [postTitle, setPostTitle] = useState('');
  const [type, setType] = useState('image');
  const [postContent, setPostContent] = useState('');
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [contentUrl, setContentURL] = useState('');
  const [postData, setPostData] = useState([]);
  const [postCount, setpostCount] = useState(0);
  const [files, setFiles] = useState([]);
  const [audio, setAudio] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentSize, setCurrentSize] = useState(10);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  // const endOffset = itemOffset + itemsPerPage;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  // const currentItems = postData.slice(itemOffset, endOffset);
  // const pageCount = Math.ceil(postData.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    // const newOffset = (event.selected * itemsPerPage) % postData.length;
    // console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    // setItemOffset(newOffset);
    console.log(event.selected);
    const userData = localdata.GET_DATA();
    setLoading(true);
    axios
      .get(Services.GET_ALL_ANNOUNCEMENT(event.selected + 1, 10), {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        console.log(`Loading items from ${event.selected} to 10`);
        setLoading(false);
        console.log(res);
        setPostData(res.data.results);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });

    setCurrentPage(event.selected);
    setCurrentSize(currentSize + 10);
  };

  const [isLoading, setLoading] = useState(true);
  const RefreshPostData = () => {
    const userData = localdata.GET_DATA();
    setLoading(true);
    axios
      .get(Services.GET_ALL_ANNOUNCEMENT(0, 10), {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setPostData(res.data.results);
        setpostCount(res.data.count);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    RefreshPostData();
  }, []);

  function getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const AddPost = async () => {
    const userData = localdata.GET_DATA();
    setLoading(true);
    let image, url;
    if (files.length) {
      image = await toBase64(files[0]);
    }
    url = getId(youtubeUrl);
    axios
      .post(
        Services.ADD_ANNOUNCEMENT(),
        {
          title: postTitle,
          message: postContent,
          userId: userData.id,
          type,
          role: 'admin',
          file: image,
          url,
          extension: files.length && files[0].name
        },
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        console.log(res);
        if (res.data.status === 200) {
          setPostData(res.data.results);
          setPostTitle('');
          setPostContent('');
          setContentURL('');
          setFiles([]);
        }
        RefreshPostData();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const search = (value) => {
    if (!value) {
      RefreshPostData()
      return;
    }
    const userData = localdata.GET_DATA();
    axios.get(Services.SEARCH_POST(), {
      params: {
        query: value
      },
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    }).then((res) => {
      setPostData(res.data.results);
      setpostCount(10);

    })
  }

  return (
    <Page title="Dashboard: Products">
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={8} sm={8} md={8}>
            <Typography variant="h4" sx={{ mb: 5 }}>
              Announcement
              {/* <TextField
                id="outlined-textarea"
                label="Search"
                style={{ width: '100%' }}
                variant="standard"
                onChange={(e) => search(e.target.value)}
              /> */}
            </Typography>
            {isLoading ? (
              <Box sx={{ display: 'flex' }}>
                <CircularProgress
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '55%',
                    translate: transform('-50%', '-50%'),
                    zIndex: 9,
                  }}
                />
              </Box>
            ) : (
              <>
                <div style={{ paddingBottom: 70 }}>
                  <PostList products={postData} RefreshPostData={RefreshPostData} isOther={true} />
                </div>
                <div style={{ width: '100%', marginTop: 20 }}>
                  <MyPaginate pageCount={postCount / 10} onPageChange={handlePageClick} forcePage={currentPage} />
                </div>
              </>
            )}
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            <Typography variant="h5" sx={{ mb: 5 }}>
              Add Announcements
            </Typography>
            <TextField
              id="outlined-textarea"
              label="Title"
              style={{ width: '100%' }}
              value={postTitle}
              onChange={(e) => setPostTitle(e.target.value)}
            />
            <TextField
              id="outlined-textarea"
              label="Write something here..."
              multiline
              minRows={2}
              maxRows={4}
              style={{ width: '100%', marginTop: 20, marginBottom: 20 }}
              value={postContent}
              onChange={(e) => setPostContent(e.target.value)}
            />
            <h5>Type</h5>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={type}
              onChange={({ target: { value } }) => setType(value)}
            >
              <FormControlLabel value="image" control={<Radio />} label="Image" onClick={() => {
                setFiles([]);
                setYoutubeUrl('');
              }} />
              <FormControlLabel value="audio" control={<Radio />} onClick={() => {
                setFiles([]);
                setYoutubeUrl('');
              }} label="Audio" />
            </RadioGroup>
            {type === 'image' &&
              <FileUpload accept={'image/*'} multiple={false} maxFiles={1} value={files} onChange={setFiles} />
            }
            {type === 'video' &&
              <TextField
                id="outlined-textarea"
                label="Youtube URL"
                style={{ width: '100%', marginTop: 20, marginBottom: 20 }}
                value={youtubeUrl}
                onChange={(e) => setYoutubeUrl(e.target.value)}
              />
            }
            {type === 'audio' &&
              <>
                <div className='file-upload'>
                  <input className="choose-file" type="file" onChange={({ target: { files } }) => {
                    console.log(files[0])
                    setFiles(files);
                    setAudio(URL.createObjectURL(files[0]));
                    document.getElementById("audio-preview").load();
                  }} />
                  <h5>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-headphones bar-icon"><path d="M3 18v-6a9 9 0 0 1 18 0v6"></path><path d="M21 19a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3zM3 19a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H3z"></path></svg>
                    Audio
                  </h5>
                </div>
                <audio id="audio-preview" controls autoPlay>
                  <source src={audio} />
                  Your browser does not support the audio element.
                </audio>
              </>
            }
            <Button
              style={{ height: 40, width: '100%', marginTop: 10 }}
              variant="contained"
              disabled={postContent === '' || postTitle === ''}
              onClick={AddPost}
            >
              Post
            </Button>
          </Grid>
        </Grid>
        {/* <ProductCartWidget /> */}
      </Container>
    </Page>
  );
}
