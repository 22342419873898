import { useContext, useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import Settings from './pages/settings/Settings';
import Update from './pages/Update';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Events from './pages/events/Events';
import Posts from './pages/Posts';
import Announcements from './pages/Announcements';
import DashboardApp from './pages/DashboardApp';
import { AuthContext } from './AuthProvider';
import Logout from './pages/logout';
import Files from './pages/files/Files';
import UserList from './pages/UserList';
import EventDetails from './pages/events/EventDetails';
import Notification from './pages/notification/Notification';

// ----------------------------------------------------------------------

export default function Router() {
  const { setUserData } = useContext(AuthContext);
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'posts', element: <Posts /> },
        { path: 'announcement', element: <Announcements /> },
        { path: 'update', element: <Update /> },
        { path: 'user', element: <User /> },
        { path: 'userList', element: <UserList /> },
        { path: 'settings', element: <Settings /> },
        { path: 'notification', element: <Notification /> },
        { path: 'event', element: <Events /> },
        { path: 'event/:id', element: <EventDetails /> },
        {
          path: 'files', element: <Files />, children: [
            { path: ':id', element: <Files /> },
          ],
        },
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'register',
      element: <Register />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: '404', element: <NotFound /> },
        { path: 'logout', element: <Logout /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
