import { filter, transform } from 'lodash';
import ReactToPrint from "react-to-print";
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Modal,
    TextField,
    Box,
    Backdrop,
    Grid,
    Tooltip,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';

// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import FormListToolbar from '../../sections/@dashboard/user/FormListToolbar';
import { UserListHead } from '../../sections/@dashboard/user';
// mock
import USERLIST from '../../_mock/user';
import Services from '../../service/Services';
import { AuthContext } from '../../AuthProvider';
import { localdata } from '../../service/localdata';
import { AppWidgetSummary } from '../../sections/@dashboard/app';
import { PrintQR } from './model/PrintQR';
import EventUserModel from './model/EventUser';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'gender', label: 'Gender', alignRight: false },
    { id: 'mobile', label: 'Mobile', alignRight: false },
    { id: 'age', label: 'Age', alignRight: false },
    { id: 'visited', label: 'Visited', alignRight: false },
    { id: 'createdAt', label: 'Created At', alignRight: false },
    { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    // boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

export default function EventDetails() {
    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);
    const [componentRef, setcomponentRef] = useState(null)

    const [orderBy, setOrderBy] = useState('name');
    const [isView, setView] = useState(false);

    const [filterName, setFilterName] = useState('');
    const [eventData, setEvent] = useState({});
    const [allCounts, setCounts] = useState({});
    const [selectedData, setSelectedData] = useState({});

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function applySortFilter(array, comparator, query, adFilter) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        if (query) {
            return paginate(rowsPerPage, page + 1, filter(
                array,
                (_user) =>
                (_user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                    _user.mobile.toLowerCase().indexOf(query.toLowerCase()) !== -1)
            ));
        }

        if (Object.keys(adFilter).length) {
            return paginate(rowsPerPage, page + 1, filter(
                array,
                (_user) => {
                    return !!_user.isWarrior === adFilter.isWarrior && !!_user.blocked === adFilter.isBlocked
                }

            ));
        }
        return paginate(rowsPerPage, page + 1, stabilizedThis.map((el) => el[0]));
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = usersFullData.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
    const { id } = useParams();

    const refreshEventData = () => {
        setLoading(true);
        const userData = localdata.GET_DATA();
        axios.get(Services.EVENTS(id), {
            headers: {
                Authorization: `Bearer ${userData.token}`,
            },
        }).then((e) => {
            setEvent(e.data.data.length ? e.data.data[0] : {});
        }).finally(() => {
            setLoading(false);
        })
    }

    const dashboardDate = () => {
        setLoading(true);
        const userData = localdata.GET_DATA();
        axios.get(`${Services.EVENT_FORM()}/dashboard/${id}`, {
            headers: {
                Authorization: `Bearer ${userData.token}`,
            },
        }).then((e) => {
            console.log(e.data.data)
            setCounts(e.data.data)
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setFilteredUsers(paginate(rowsPerPage, newPage + 1));
    };

    const paginate = (pageSize, pageNumber, array) => {
        // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
        const res = [...[...(array || usersFullData)].slice((pageNumber - 1) * pageSize, pageNumber * pageSize)];

        return res;
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setFilteredUsers(paginate(parseInt(event.target.value, 10)), 1);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

    const [filteredUsers, setFilteredUsers] = useState([]);
    const [usersFullData, setUsersFullData] = useState([]);
    // const isUserNotFound = filteredUsers.length === 0;

    const [updateLoading, setUpdateLoading] = useState(false);

    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [success2, setSuccess2] = useState(false);
    const [adFilter, setAdFilter] = useState({});

    const userUpdateStatus = (id, data) => {
        const userData = localdata.GET_DATA();
        axios
            .put(Services.BLOCK_UNBLOCK_USER(id), { status: data }, {
                headers: {
                    Authorization: `Bearer ${userData.token}`,
                },
            })
            .then((res) => {
                console.log(res);
                RefreshData();

                setSuccess2(true);
                setTimeout(() => {
                    setSuccess2(false);
                }, 3000);
            })
            .catch((err) => {
                console.log(err);
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 3000);
            });
    };

    // applySortFilter(USERLIST, getComparator(order, orderBy), filterName);
    const [isLoading, setLoading] = useState(true);
    const RefreshData = () => {
        dashboardDate();
        const userData = localdata.GET_DATA();
        setLoading(true);
        axios
            .get(`${Services.EVENT_FORM()}/user/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData.token}`,
                },
            })
            .then((res) => {
                console.log(res.data);
                if (res.data.data.length > 0) {
                    setFilteredUsers(paginate(rowsPerPage, page + 1, res.data.data));
                    setUsersFullData(res.data.data);
                }
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const updateStatus = (status) => {
        console.log(selected, status)
        // axios.put(Services.EVENT_FORM())
        const userData = localdata.GET_DATA();
        const apiDetails = selected.map(e => {
            return axios.put(Services.EVENT_FORM(e), { visited: status }, {
                headers: {
                    Authorization: `Bearer ${userData.token}`,
                },
            });
        });
        Promise.all(apiDetails).then(() => {
            RefreshData();
            setSelected([]);
        })
    }

    const updateEventStatus = (status) => {
        const userData = localdata.GET_DATA();
        setLoading(true);
        axios.put(`${Services.EVENTS(eventData.id)}`, {
            isActive: status
        }, {
            headers: {
                Authorization: `Bearer ${userData.token}`,
            },
        })
            .then((res) => {
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    }

    const setComponentRef = (ref) => {
        setcomponentRef(ref);
    };

    useEffect(() => {
        RefreshData();
        refreshEventData();
    }, [id]);

    const NewfilteredUsers = applySortFilter(usersFullData, getComparator(order, orderBy), filterName, adFilter);

    return (
        <Page title={eventData.title}>

            {success && (
                <Alert variant="filled" severity="success" style={{ position: 'absolute', top: 100, right: 10, zIndex: 999 }}>
                    <AlertTitle>Success</AlertTitle>
                    Account deleted successfully
                </Alert>
            )}

            {error && (
                <Alert variant="filled" severity="error" style={{ position: 'absolute', top: 100, right: 10, zIndex: 999 }}>
                    <AlertTitle>Error</AlertTitle>
                    Something Wrong <strong>check it out!</strong>
                </Alert>
            )}

            {success2 && (
                <Alert variant="filled" severity="success" style={{ position: 'absolute', top: 100, right: 10, zIndex: 999 }}>
                    <AlertTitle>Success</AlertTitle>
                    User details updated successfully
                </Alert>
            )}

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <div>
                        <h3>{eventData.title} <Label>{eventData.isActive ? 'Active' : 'In Active'}</Label>
                        </h3>
                        <p className="text-secondary">{eventData.description}</p>
                    </div>
                    <div>

                        <Button variant="contained" component={RouterLink} to="/dashboard/event" startIcon={<Iconify icon="eva:arrow-back-outline" />}>
                            Back
                        </Button>
                        <Tooltip title="Copy URL">
                            <Button variant="contained" className="me-1 float-start" onClick={() => {
                                navigator.clipboard.writeText(`https://salvationlamb.com/event/SL-${eventData.id}`)
                            }}>
                                <Iconify icon="eva:copy-outline" width={20} height={25} />
                            </Button>
                        </Tooltip>


                        <ReactToPrint content={() => {
                            return componentRef;
                        }} trigger={() => (<Tooltip title="Export QR Code"><Button variant="contained" className="me-1 float-start">
                            <Iconify icon="eva:keypad-outline" width={20} height={25} />
                        </Button></Tooltip>)} />
                        <Tooltip title="Active/InActive Event">
                            <Button variant="contained" className="me-1 float-start" onClick={() => {
                                updateEventStatus(!eventData.isActive)
                            }}>
                                {eventData.isActive
                                    ? <Iconify icon="eva:slash-outline" width={20} height={25} />
                                    : <Iconify icon="eva:checkmark-circle-outline" width={20} height={25} />
                                }
                            </Button>
                        </Tooltip>

                    </div>
                </Stack>
                <div className='d-none'><PrintQR event={eventData} ref={setComponentRef} /></div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999999 }}
                    open={updateLoading}
                    onClick={() => setUpdateLoading(false)}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Grid container spacing={3} className='mb-4'>
                    <Grid item xs={12} sm={6} md={2}>
                        <AppWidgetSummary title="Total users" total={allCounts.totalUser} icon={'mdi:users-group-outline'} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={2}>
                        <AppWidgetSummary title="Total Male" total={allCounts.totalMale} color="info" icon={'eva:stop-circle-outline'} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={2}>
                        <AppWidgetSummary
                            title="Total Female"
                            total={allCounts.totalFemale}
                            color="warning"
                            icon={'eva:radio-button-on-outline'}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={2}>
                        <AppWidgetSummary
                            title="Bellow 13 Years"
                            total={allCounts.bellow12}
                            color="error"
                            icon={'eva:color-picker-outline'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <AppWidgetSummary
                            title="Visited"
                            total={allCounts.visited}
                            color="primary"
                            icon={'eva:person-done-outline'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <AppWidgetSummary
                            title="Not Visisted"
                            total={allCounts.notVisited}
                            color="error"
                            icon={'eva:person-delete-outline'}
                        />
                    </Grid>
                </Grid>
                {isLoading ? (
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '55%',
                                translate: transform('-50%', '-50%'),
                                zIndex: 9,
                            }}
                        />
                    </Box>
                ) : (
                    <Card>


                        <FormListToolbar
                            updateFilter={(data) => {
                                setAdFilter(data);
                            }}
                            url={`${Services.EVENT_FORM()}/export/${id}?eventName=${eventData.title}`}
                            bulkUpdate={updateStatus}
                            numSelected={selected.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                        />

                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <UserListHead
                                        order={order}
                                        orderBy={orderBy}
                                        isMultiSelect
                                        headLabel={TABLE_HEAD}
                                        rowCount={usersFullData.length}
                                        numSelected={selected.length}
                                        onRequestSort={handleRequestSort}
                                        onSelectAllClick={handleSelectAllClick}
                                    />
                                    <TableBody>
                                        {NewfilteredUsers.length > 0
                                            ? NewfilteredUsers.map((row) => {
                                                const { id, name, gender, family, age, mobile, isVisited, picture, createdAt } = row;
                                                const isItemSelected = selected.indexOf(id) !== -1;

                                                return (
                                                    <TableRow
                                                        hover
                                                        onClick={(event) => handleClick(event, id)}
                                                        key={id}
                                                        tabIndex={-1}
                                                        role="checkbox"
                                                        selected={isItemSelected}
                                                        aria-checked={isItemSelected}
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                color="primary"
                                                                checked={isItemSelected}
                                                                inputProps={{
                                                                    'aria-labelledby': id,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" padding="none">
                                                            <Stack direction="row" alignItems="center" spacing={10} style={{ marginLeft: 20 }}>
                                                                <Avatar alt={name} src={picture} />
                                                                <Typography variant="subtitle2" noWrap style={{ marginLeft: 10 }}>
                                                                    {name}
                                                                </Typography>
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell align="left">{gender}</TableCell>
                                                        <TableCell align="left">{row.eventForm?.mobile}</TableCell>
                                                        <TableCell align="left">{age}</TableCell>
                                                        <TableCell align="left">{isVisited ?
                                                            <h6 className='text-success d-flex align-middle'> <Iconify className="me-2" icon="eva:done-all-outline" /> Yes</h6> :
                                                            <h6 className='text-danger d-flex align-middle'> <Iconify className="me-2" icon="eva:close-circle-outline" /> No</h6>}</TableCell>
                                                        <TableCell align="left">{moment(createdAt).format('YYYY-MM-DD hh:mm A')}</TableCell>
                                                        <TableCell align="left" className=''>
                                                            <h4 className='text-primary d-flex align-middle'>
                                                                <Iconify className="me-2" icon="eva:eye-outline" onClick={() => {
                                                                    console.log(row)
                                                                    setSelectedData(row);
                                                                    setView(true);
                                                                }} />
                                                            </h4>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                            : null}
                                    </TableBody>

                                    {NewfilteredUsers.length === 0 && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                    <SearchNotFound searchQuery={filterName} />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={usersFullData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>
                )}
                <EventUserModel open={isView} data={selectedData} onClose={(e) => {
                    setView(false);
                }} />
            </Container>
        </Page>
    );
}
