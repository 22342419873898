import { useEffect, useState } from 'react';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';

// material
import {
    Modal,
    TextField,
    Box,
    Typography,
} from '@mui/material';
import Services from '../../../service/Services';
import { localdata } from '../../../service/localdata';
import Iconify from '../../../components/Iconify';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    // boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

const EventUserModel = ({ open, onClose, data }) => {
    const [editedData, setEditedData] = useState({});
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setEditedData({ ...data });
    }, [data])

    const deleteForm = () => {
        const userData = localdata.GET_DATA();
        setLoading(true);
        axios.delete(`${Services.EVENT_FORM(editedData.id)}`, {
            headers: {
                Authorization: `Bearer ${userData.token}`,
            },
        }).then(() => {
            window.location.reload();
        }).finally(() => {
            setLoading(false);
        });
       
    }

   

 

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" style={{ marginBottom: 10 }}>
                    Details About {data.name}
                </Typography>

                <Grid container spacing={2} className='mt-2'>

                    <Grid item xs={6}>
                        <h5>Mobile</h5>
                        <p>{data.mobile}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <h5>Attend with Family</h5>
                        <p>{data.family ? 'Yes' : 'No'}</p>
                    </Grid>
                    <Grid item xs={12}>
                        <h5>Family Members</h5>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Gender</th>
                                    <th>Age</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.eventUser?.length && data.eventUser?.map((e, i) => {

                                        return (
                                            <tr>
                                                <td>{e.name}</td>
                                                <td>{e.gender}</td>
                                                <td>{e.age}</td>
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    !data.eventUser?.length &&
                                    <tr>
                                        <td colSpan={3}>No Records</td>
                                    </tr>

                                }
                            </tbody>
                        </table>

                        <p>
                            <b className='text-danger'>Note</b>
                            <br />
                            Once You click the delete button it will permenatly delete those records you can't recover it
                        </p>
                    </Grid>
                </Grid>
                <Grid container className='mt-4' spacing={2}>
                    <Grid item xs={6} />
                    <Grid item xs={6} className="text-end">
                        <LoadingButton
                            onClick={onClose}
                            size="large"
                            type="submit"
                            className='me-3'
                            variant="outlined"
                            disabled={isLoading}
                            sx={{ my: 1 }}
                        >
                            Cancel
                        </LoadingButton>
                        <LoadingButton
                            onClick={deleteForm}
                            loading={isLoading}
                            size="large"
                            type="submit"
                            color='error'
                            variant="contained"
                            sx={{ my: 1 }}
                        >
                            Delete
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}

export default EventUserModel;
