import { useEffect, useState } from 'react';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';

// material
import {
    Modal,
    TextField,
    Box,
    Typography,
} from '@mui/material';
import Services from '../../../../service/Services';
import { localdata } from '../../../../service/localdata';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    // boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

const GroupModel = ({ open, onClose, data }) => {
    const [editedData, setEditedData] = useState({});
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setEditedData({ ...data });
    }, [data])

    const submitData = () => {
        const userData = localdata.GET_DATA();
        setLoading(true);
        if (editedData.id) {
            axios.put(Services.GROUP(editedData.id), editedData, {
                headers: {
                    Authorization: `Bearer ${userData.token}`,
                },
            }).then(() => {
                onClose();
            }).finally(() => {
                setLoading(false);
            });
            return;
        }
        axios.post(Services.GROUP(), editedData, {
            headers: {
                Authorization: `Bearer ${userData.token}`,
            },
        }).then(() => {
            onClose();
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" style={{ marginBottom: 10 }}>
                    Add/Update User Group
                </Typography>

                <Grid container spacing={2} className='mt-2'>
                    <Grid item xs={12}>
                        <TextField
                            label="Group Name"
                            className='w-100'
                            value={editedData.name}
                            onChange={(e) => setEditedData({ ...editedData, name: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            className='w-100'
                            label="Eligible Mark"
                            type={'number'}
                            value={editedData.mark}
                            onChange={(e) => setEditedData({ ...editedData, mark: e.target.value })}
                        />
                    </Grid>
                </Grid>
                <Grid container className='mt-4' spacing={2}>
                    <Grid item xs={6} />
                    <Grid item xs={6} className="text-end">
                        <LoadingButton
                            onClick={onClose}
                            size="large"
                            type="submit"
                            className='me-3'
                            variant="outlined"
                            sx={{ my: 1 }}
                        >
                            Cancel
                        </LoadingButton>
                        <LoadingButton
                            onClick={submitData}
                            loading={isLoading}
                            size="large"
                            type="submit"
                            variant="contained"
                            sx={{ my: 1 }}
                        >
                            Update
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}

export default GroupModel;
