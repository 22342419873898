import PropTypes from 'prop-types';
import { useState } from 'react';
// material
import { styled } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 340,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function UserListToolbar({ numSelected, filterName, onFilterName, bulkUpdate, updateFilter, url }) {
  const [isWarrior, setWarrior] = useState(false);
  const [isBlocked, setBlocked] = useState(false);
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          label="Search"
          placeholder="Search user..."
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
      )}

      {numSelected > 0 ? (<div>
        <Tooltip title="Not Visited">
          <IconButton onClick={() => bulkUpdate(false)}>
            <Iconify icon="eva:person-delete-outline" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Visited">
          <IconButton onClick={() => bulkUpdate(true)}>
            <Iconify icon="eva:person-done-outline" />
          </IconButton>
        </Tooltip>
      </div>
      ) : (<div>
        <Tooltip title="Download in Excel">
          <IconButton onClick={() => {
            console.log(url)
            window.open(url, '_blank')
          }}>
            <Iconify icon="eva:cloud-download-outline" />
          </IconButton>
        </Tooltip>
      </div>
      )}
    </RootStyle >
  );
}
